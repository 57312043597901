<template>
  <Layout>
    <b-tabs>
      <b-tab title="English">
        <div class="row" id="case-carousel">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <form-wizard
                  @on-complete="submitData"
                  color="#556ee6"
                  enctype="multipart/form-data"
                  ref="case"
                  :finishButtonText="
                    this.$route.name == 'add-case' ? 'Save Data' : 'Update Data'
                  "
                  back-button-text="Go Back!"
                  next-button-text="Go Next!"
                >
                  <tab-content
                    title="Case Details"
                    icon="mdi mdi-comment"
                    :before-change="() => validateFormOne()"
                  >
                    <div class="row">
                      <div class="col-12">
                        <b-form ref="step1">
                          <div class="row">
                            <b-form-group id="input-group-1" class="col-6">
                              <label for="case_title"
                                >Case Title
                                <span style="color: red">*</span></label
                              >
                              <b-form-input
                                id="case_title"
                                v-model="form.title"
                                @keyup.prevent="slugify"
                                placeholder="Enter Case Title"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.form.title.$invalid,
                                }"
                              >
                              </b-form-input>
                              <div
                                v-if="submitted && !$v.form.title.required"
                                class="invalid-feedback"
                              >
                                Case Title is required.
                              </div>
                            </b-form-group>
                            <b-form-group class="col-6">
                              <label for="case_url_link"
                                >URL Link
                                <span style="color: red">*</span></label
                              >
                              <b-form-input
                                id="case_url_link"
                                v-model="form.url_link"
                                placeholder="Enter URL Link"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.form.url_link.$invalid,
                                }"
                              >
                              </b-form-input>
                              <div
                                v-if="submitted && !$v.form.url_link.required"
                                class="invalid-feedback"
                              >
                                URL Link is required.
                              </div>
                            </b-form-group>
                            <b-form-group id="input-group-2" class="col-12">
                              <label for="case_desc"
                                >Tell Us More About The Case
                                <span style="color: red">*</span></label
                              >
                              <ckeditor
                                v-model="form.description"
                                :editor="editor"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.form.description.$invalid,
                                }"
                              ></ckeditor>
                              <div
                                v-if="
                                  submitted && !$v.form.description.required
                                "
                                class="invalid-feedback"
                              >
                                Description is required.
                              </div>
                            </b-form-group>
                            <b-form-group class="col-12">
                              <label for="case_ques_type"
                                >Case Question Type
                                <span style="color: red">*</span></label
                              >
                              <b-form-select
                                id="case_ques_type"
                                @input="questionType"
                                v-model="form.question_type"
                                value-field="value"
                                text-field="text"
                                :options="case_question_options"
                                :class="{
                                  'is-invalid':
                                    submitted && $v.form.question_type.$invalid,
                                }"
                              >
                              </b-form-select>
                              <div
                                v-if="
                                  submitted && !$v.form.question_type.required
                                "
                                class="invalid-feedback"
                              >
                                Type is required.
                              </div>
                            </b-form-group>
                            <div
                              v-if="form.question_type == 'mcq'"
                              class="col-12"
                            >
                              <b-form-group>
                                <b-button
                                  variant="success"
                                  class="float-right"
                                  @click.prevent="addmore"
                                >
                                  Add More</b-button
                                >
                              </b-form-group>
                              <b-carousel
                                :interval="interval"
                                ref="myCarousel"
                                id="carousel-1"
                                v-model="slide"
                                @setSlide="setSlide"
                                indicators
                              >
                                <b-carousel-slide
                                  v-for="(addmore, key) in form.addMore"
                                  :key="key"
                                >
                                  <b-form-group id="input-group-2">
                                    <label
                                      >Question
                                      <span style="color: red">*</span></label
                                    >
                                    <b-form-input
                                      id="enter_case_ques"
                                      v-model="addmore.question"
                                      placeholder="Enter Question"
                                      :class="{
                                        'is-invalid':
                                          submitted &&
                                          $v.form.addMore.$each[key].question
                                            .$invalid,
                                      }"
                                    >
                                    </b-form-input>
                                    <div
                                      v-if="
                                        submitted &&
                                        !$v.form.addMore.$each[key].question
                                          .required
                                      "
                                      class="invalid-feedback"
                                    >
                                      Question is required.
                                    </div>
                                  </b-form-group>
                                  <div
                                    class="row"
                                    v-for="(val, index) in addmore.option"
                                    :key="index"
                                  >
                                    <div class="col-md-6">
                                      <b-form-group id="input-group-1">
                                        <label for="case_add_more"
                                          >Option
                                          {{ index + 1 }}
                                          <span style="color: red"
                                            >*</span
                                          ></label
                                        >
                                        <b-form-input
                                          id="case_add_more"
                                          v-model="val.value"
                                          :key="val.key"
                                          placeholder="Enter Option"
                                          @keydown.space="preventLeadingSpace"
                                        >
                                        </b-form-input>
                                      </b-form-group>
                                    </div>
                                    <div
                                      class="col-md-4"
                                      style="
                                        margin-top: 30px;
                                        text-align: start;
                                      "
                                    >
                                      <b-button
                                        variant="success"
                                        class="mr-3"
                                        @click.prevent="addOption(key)"
                                        :disabled="val.value.length == 0"
                                        >Add Option</b-button
                                      >
                                      <b-button
                                        variant="danger"
                                        @click.prevent="
                                          removeOption(index, key)
                                        "
                                        >Remove Option</b-button
                                      >
                                    </div>
                                  </div>
                                  <b-form-group>
                                    <label for="case_correct_option"
                                      >Select Correct Option
                                      <span style="color: red">*</span></label
                                    >
                                    <b-form-select
                                      id="case_correct_option"
                                      v-model="addmore.correct_option"
                                      :options="addmore.option"
                                      value-field="key"
                                      text-field="value"
                                      :class="{
                                        'is-invalid':
                                          submitted &&
                                          $v.form.addMore.$each[key]
                                            .correct_option.$invalid,
                                      }"
                                    >
                                    </b-form-select>
                                    <div
                                      v-if="
                                        submitted &&
                                        !$v.form.addMore.$each[key]
                                          .correct_option.required
                                      "
                                      class="invalid-feedback"
                                    >
                                      Correct Option is required.
                                    </div>
                                  </b-form-group>
                                  <b-form-group
                                    id="input-group-2"
                                    label="Describe the Correct Answer"
                                    label-for="case_desc"
                                  >
                                    <ckeditor
                                      v-model="addmore.answer_details"
                                      :editor="editor"
                                    ></ckeditor>
                                  </b-form-group>
                                  <template
                                    v-if="addmore.type == 'discussion_forum'"
                                  >
                                    <b-form-group
                                      id="example-date"
                                      label="Show Answer At"
                                      label-for="date"
                                    >
                                      <b-form-input
                                        id="date"
                                        type="datetime-local"
                                        v-model="addmore.show_answer_at"
                                        :min="disabledDates()"
                                      >
                                      </b-form-input>
                                    </b-form-group>
                                  </template>
                                </b-carousel-slide>
                              </b-carousel>
                            </div>
                            <b-form-group class="case-img col-12">
                              <div class="d-flex justify-content-between">
                                <label for="case_image"
                                  >Add Image [Multiple Selection allowed]
                                  [Upload Max File Size : 20 MB]</label
                                >
                                <template
                                  v-if="
                                    $route.name == 'edit-case' &&
                                    images.length > 0
                                  "
                                >
                                  <a
                                    href="javascript:void(0);"
                                    @click="downloadZip()"
                                  >
                                    <i class="fa fa-download"></i>
                                    Download Images
                                  </a>
                                </template>
                              </div>
                              <b-form-file
                                id="case_image"
                                ref="case_images"
                                accept="image/png, image/jpeg, image/jpg"
                                multiple
                                placeholder="Choose a file or drop it here..."
                                @change="readFile($event, 'image')"
                              >
                              </b-form-file>
                              <div class="case-images">
                                <template
                                  v-if="
                                    $route.name == 'edit-case' &&
                                    images.length > 0
                                  "
                                >
                                  <template v-for="(item, index) in images">
                                    <div
                                      style="position: relative"
                                      id="delete-img"
                                      :key="index"
                                      v-if="item.type == 'image'"
                                    >
                                      <div
                                        @click.prevent="
                                          deleteImage(item.id, index, 'image')
                                        "
                                        v-if="item.type == 'image'"
                                      >
                                        <i
                                          v-if="item.id"
                                          class="mdi mdi-close-circle cross"
                                        ></i>
                                      </div>
                                      <img
                                        :key="index"
                                        :src="item.image"
                                        v-if="item.type == 'image'"
                                      />
                                    </div>
                                  </template>
                                </template>
                                <template
                                  v-if="
                                    $route.name == 'add-case' &&
                                    images.length > 0
                                  "
                                >
                                  <div
                                    v-for="(item, index) in images"
                                    :key="index"
                                  >
                                    <img
                                      :key="index"
                                      :src="item.image"
                                      width="128px"
                                      height="128px"
                                      v-if="item.type == 'image'"
                                    />
                                  </div>
                                </template>
                              </div>
                            </b-form-group>
                            <b-form-group class="col-12">
                              <label
                                >Add Video [Multiple Selection allowed] [Upload
                                Max File Size : 20 MB]</label
                              >
                              <b-form-file
                                ref="case_videos"
                                placeholder="Choose a video file or drop it here..."
                                drop-placeholder="Drop video file here..."
                                accept="video/mp4"
                                @change="readFile($event, 'video')"
                                multiple
                              ></b-form-file>
                              <template
                                v-if="
                                  ($route.name == 'add-case' ||
                                    $route.name == 'edit-case') &&
                                  videos.length > 0
                                "
                              >
                                <div class="case_video_grid">
                                  <template v-for="(item, index) in videos">
                                    <div
                                      :key="index"
                                      class="case-video"
                                      v-if="item.type == 'video'"
                                    >
                                      <div class="position-relative">
                                        <div
                                          @click.prevent="
                                            deleteImage(item.id, index, 'video')
                                          "
                                          v-if="item.type == 'video'"
                                          class="case_video_cross"
                                        >
                                          <i
                                            v-if="item.id"
                                            class="mdi mdi-close-circle"
                                          ></i>
                                        </div>
                                        <video
                                          width="358px"
                                          height="200px"
                                          controls
                                        >
                                          <source
                                            :src="`${
                                              item.image
                                                ? item.image
                                                : item.video
                                            }`"
                                            type="video/mp4"
                                          />
                                        </video>
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </template>
                            </b-form-group>
                          </div>
                        </b-form>
                      </div>
                    </div>
                  </tab-content>
                  <tab-content
                    title="Add Tags"
                    icon="mdi mdi-file-image"
                    :before-change="() => validateFormTwo()"
                  >
                    <div class="row">
                      <b-form-group class="col-6">
                        <div
                          class="d-flex justify-content-between align-items-center mb-2"
                        >
                          <label for="comm" class="mb-0"
                            >Community <span style="color: red"> *</span></label
                          >
                          <b-form-checkbox
                            v-model="status"
                            button
                            button-variant="info"
                            size="sm"
                          >
                            <template v-if="status">Unselect All</template>
                            <template v-else>Select All</template>
                          </b-form-checkbox>
                        </div>
                        <multiselect
                          @search-change="fetchCommunity"
                          id="comm"
                          v-model="form.community_selected"
                          :options="community"
                          :multiple="true"
                          track-by="id"
                          label="title"
                          placeholder="Type here to search"
                          :class="{
                            'is-invalid':
                              store && $v.form.community_selected.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.community_selected.required"
                          class="invalid-feedback"
                        >
                          Community is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" label-for="input-multi">
                        <div
                          class="d-flex justify-content-between align-items-center mb-1"
                        >
                          <label for="input-multi" style="margin-top: 5px"
                            >Country</label
                          >
                        </div>
                        <multiselect
                          @search-change="fetchAllCountry"
                          id="input-multi"
                          v-model="form.country"
                          :options="allCountry"
                          :multiple="true"
                          track-by="id"
                          label="name"
                          placeholder="Type here to search"
                        >
                        </multiselect>
                        <span slot="noOptions"> Type here to search </span>
                      </b-form-group>
                      <b-form-group
                        label="Sub Speciality"
                        label-for="video_sub_spec"
                        class="col-6"
                      >
                        <multiselect
                          id="video_sub_spec"
                          v-model="form.sub_specialities"
                          :options="subspecialities"
                          :multiple="true"
                          track-by="id"
                          label="name"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        class="col-6"
                        label="Knowledge Category"
                        label-for="video_knowledge"
                      >
                        <multiselect
                          id="video_knowledge"
                          v-model="form.knowledge_category"
                          :options="knowledgeCategories"
                          :multiple="true"
                          track-by="id"
                          label="display_name"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                      </b-form-group>
                      <b-form-group
                        label="Enter Free tags"
                        label-for="tags-separators"
                        class="col-6"
                      >
                        <b-form-tags
                          input-id="tags-separators"
                          v-model="form.tags"
                          tag-variant="primary"
                          tag-pills
                          separator=" "
                          placeholder="Enter new tags separated by space and enter"
                        >
                        </b-form-tags>
                      </b-form-group>
                      <b-form-group class="col-6">
                        <div
                          class="my-1 d-flex justify-content-between align-items-center"
                        >
                          <label label-for="in-forum" class="mb-1"
                            >Expert</label
                          >
                          <div
                            @click="removeExpertOption"
                            style="cursor: pointer"
                            v-if="form.expert_id != ''"
                          >
                            <img
                              src="@/assets/images/close.png"
                              height="12px"
                            />
                          </div>
                        </div>
                        <multiselect
                          @search-change="fetchExpert"
                          id="exp"
                          v-model="form.expert_id"
                          :options="expert"
                          :multiple="false"
                          track-by="id"
                          label="name"
                          placeholder="Type here to search"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                      </b-form-group>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="thumbnail-card grid-case">
                          <div class="">
                            <b-form-group v-slot="{ ariaDescribedby }">
                              <label class="my-2"
                                >Posted By
                                <span style="color: red">*</span></label
                              >
                              <b-form-radio-group
                                id="radio-group-1"
                                v-model="form.card_image_show"
                                :options="doctorPostType"
                                :aria-describedby="ariaDescribedby"
                                name="radio-options"
                              ></b-form-radio-group>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <b-form-group class="col-6" v-if="form.card_image_show">
                        <div
                          class="my-1 d-flex justify-content-between align-items-center"
                        >
                          <label label-for="in-forum" class="mb-1"
                            >Member</label
                          >
                        </div>
                        <multiselect
                          @search-change="fetchMemberList"
                          id="member"
                          v-model="form.member_tagging"
                          :options="memberList"
                          :multiple="false"
                          track-by="member_id"
                          label="fnameAndLname"
                          placeholder="Type here to search"
                          :class="{
                            'is-invalid':
                              store && $v.form.member_tagging.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.member_tagging.required"
                          class="invalid-feedback"
                        >
                          Member is required.
                        </div>
                      </b-form-group>
                      <b-form-group class="col-6" v-if="form.card_image_show">
                        <div
                          class="my-1 d-flex justify-content-between align-items-center"
                        >
                          <label label-for="in-forum" class="mb-1">Forum</label>
                          <div
                            @click="removeForumOption"
                            style="cursor: pointer"
                            v-if="form.partner_division_id != ''"
                          >
                            <img
                              src="@/assets/images/close.png"
                              height="12px"
                            />
                          </div>
                        </div>
                        <multiselect
                          @search-change="fetchForum"
                          id="in-forum"
                          v-model="form.partner_division_id"
                          :options="forum"
                          :multiple="false"
                          track-by="id"
                          label="title"
                          placeholder="Type here to search"
                          :class="{
                            'is-invalid':
                              store && $v.form.partner_division_id.$invalid,
                          }"
                        >
                          <span slot="noOptions"> Type here to search </span>
                        </multiselect>
                        <div
                          v-if="store && !$v.form.partner_division_id.required"
                          class="invalid-feedback"
                        >
                          Forum is required.
                        </div>
                      </b-form-group>
                      <div
                        class="col-12"
                        v-if="form.card_image_show && form.member_tagging"
                      >
                        <h6>
                          Below is the preview of Doctor posted in forum :
                        </h6>
                        <div class="dc_doctor_main_grid my-4">
                          <div
                            class="dc_doctors_grid"
                            :style="{
                              gridGap:
                                form.card_image_show === 4 ||
                                form.card_image_show === 3
                                  ? '0px'
                                  : '5px',
                              height: '75px',
                            }"
                          >
                            <div class="">
                              <img
                                src="@/assets/images/only_doctor.png"
                                alt=""
                                v-if="form.card_image_show === 4"
                                height="50px"
                                width="50px"
                              />
                              <img
                                src="@/assets/images/only_community.png"
                                alt=""
                                v-if="form.card_image_show === 3"
                                height="50px"
                                width="50px"
                              />
                              <div
                                v-if="
                                  form.card_image_show === 1 ||
                                  form.card_image_show === 2
                                "
                                class="position-relative"
                              >
                                <img
                                  src="@/assets/images/only_community.png"
                                  alt=""
                                  height="40px"
                                  width="40px"
                                  :style="{
                                    zIndex:
                                      form.card_image_show === 1 ? '20' : '10',
                                  }"
                                  class="dc_comm_abs"
                                />
                                <img
                                  src="@/assets/images/only_doctor.png"
                                  alt=""
                                  height="40px"
                                  width="40px"
                                  class="dc_doctor_abs"
                                  :style="{
                                    zIndex:
                                      form.card_image_show === 1 ? '10' : '20',
                                  }"
                                />
                              </div>
                            </div>
                            <div class="dc_main_grid">
                              <div
                                class="dc_member_name"
                                v-if="form.member_tagging.fnameAndLname"
                              >
                                {{
                                  form.member_tagging.fnameAndLname.replace(
                                    /[-0-9]/g,
                                    ""
                                  )
                                }}
                              </div>
                              <div
                                class="dc_spec_city"
                                v-if="
                                  member_speciality && form.member_tagging.city
                                "
                              >
                                {{ member_speciality }} ·
                                {{ form.member_tagging.city }}
                              </div>
                              <div
                                class="dc_spec_city"
                                v-if="
                                  form.partner_division_id &&
                                  form.card_image_show != 4
                                "
                              >
                                posted in
                                <span class="dc_forum">{{
                                  form.partner_division_id.title
                                }}</span>
                              </div>
                            </div>
                          </div>
                          <div
                            class="dc_desc px-4"
                            v-html="form.description"
                          ></div>
                          <div>
                            <img
                              src="@/assets/images/thumbnail.jpg"
                              class="dc_thumbnail"
                            />
                          </div>
                          <div
                            class="dc_useful_interaction px-2 pb-3"
                            style="grid-template-columns: 1fr 1fr 1fr 1fr"
                          >
                            <div>
                              <img
                                src="@/assets/images/usefullInteraction/usefull.png"
                              />
                              <span class="ml-2">Useful</span>
                            </div>
                            <div>
                              <img
                                src="@/assets/images/usefullInteraction/save.png"
                              />
                              <span class="ml-2">Save</span>
                            </div>
                            <div>
                              <img
                                src="@/assets/images/usefullInteraction/comment.png"
                              />
                              <span class="ml-2">Comment</span>
                            </div>
                            <div>
                              <img
                                src="@/assets/images/usefullInteraction/interaction.png"
                              />
                              <span class="ml-2">Share</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <b-form-group
                        label="View Multiplication Factor"
                        label-for="view_multi_factor"
                        class="col-4"
                      >
                        <b-form-input
                          id="view_multi_factor"
                          type="number"
                          placeholder="Enter View Multiplication Factor"
                          v-model="form.view_multiplication_factor"
                        >
                        </b-form-input>
                      </b-form-group>
                      <b-form-group
                        class="col-4 position-relative"
                        label="Meta Title"
                        label-for="input-2"
                      >
                        <b-form-input
                          :maxlength="250"
                          id="input-2"
                          v-model="form.meta_title"
                          placeholder="Enter Meta Title"
                        >
                        </b-form-input>
                        <div class="text-right">
                          <p
                            v-if="form.meta_title"
                            class="badge position-absolute"
                            style="top: 4px; right: 13px"
                            :class="{
                              'badge-success': form.meta_title.length !== 250,
                              'badge-danger': form.meta_title.length === 250,
                            }"
                          >
                            You typed
                            {{ form.meta_title.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                      <b-form-group
                        class="col-4 position-relative"
                        id="input-group-7"
                        label="Meta Keywords"
                        label-for="input-4"
                      >
                        <b-form-input
                          id="input-4"
                          v-model="form.meta_keywords"
                          placeholder=" Enter Meta Keywords"
                          :maxlength="250"
                        ></b-form-input>
                        <div class="text-right">
                          <p
                            v-if="form.meta_keywords"
                            class="badge position-absolute"
                            style="top: 4px; right: 13px"
                            :class="{
                              'badge-success':
                                form.meta_keywords.length !== 250,
                              'badge-danger': form.meta_keywords.length === 250,
                            }"
                          >
                            You typed
                            {{ form.meta_keywords.length }} out of 250 chars.
                          </p>
                        </div>
                      </b-form-group>
                    </div>
                    <b-form-group
                      id="input-group-3"
                      label="Meta Description"
                      label-for="input-3"
                      class="position-relative"
                    >
                      <b-form-textarea
                        id="input-3"
                        v-model="form.meta_desc"
                        placeholder="Enter Meta Description"
                        rows="3"
                        :maxlength="250"
                      ></b-form-textarea>
                      <div class="text-right">
                        <p
                          v-if="form.meta_desc"
                          class="badge position-absolute"
                          style="top: 4px; right: 13px"
                          :class="{
                            'badge-success': form.meta_desc.length !== 250,
                            'badge-danger': form.meta_desc.length === 250,
                          }"
                        >
                          You typed
                          {{ form.meta_desc.length }} out of 250 chars.
                        </p>
                      </div>
                    </b-form-group>
                    <b-form-group class="col-12">
                      <div style="display: flex; gap: 10px; padding: 2px 0">
                        <b-form-checkbox v-model="form.visible_on_doctor_profile"
                          >Visible to doctor profile only</b-form-checkbox
                        >
                      </div>
                    </b-form-group>
                    <b-form-group id="input-group-26">
                      <b-form-checkbox v-model="form.is_brand_material"
                        >Is Brand Material</b-form-checkbox
                      >
                    </b-form-group>
                  </tab-content>
                </form-wizard>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Indonesia">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <b-form-group id="input-group-1">
                  <label for="case_title">Case Title</label>
                  <b-form-input
                    id="case_title"
                    v-model="form.translation.indonesia.title"
                    placeholder="Enter Case Title"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="input-group-2">
                  <label for="case_desc">Tell Us More About The Case</label>
                  <ckeditor
                    v-model="form.translation.indonesia.description"
                    :editor="editor"
                  ></ckeditor>
                </b-form-group>
                <b-form-group
                  class="case-img"
                  id="input-group-2"
                  label="Add Images [Multiple Selection allowed] [Upload Max File Size : 20 MB]"
                  label-for="case_image1"
                >
                  <b-form-file
                    id="case_image1"
                    ref="indonesia_item_image"
                    accept="image/*"
                    multiple="multiple"
                    placeholder="Choose a file or drop it here..."
                    @change="readFile($event, 'indonesia_image')"
                  ></b-form-file>
                  <template
                    v-if="
                      $route.name == 'edit-case' &&
                      image_name_indonesia.length > 0
                    "
                  >
                    <img
                      v-for="(image, index) in image_name_indonesia"
                      :key="index"
                      :src="image"
                      width="128px"
                      height="128px"
                    />
                  </template>
                  <template v-if="$route.name == 'add-case'">
                    <img
                      v-for="image in image_name_indonesia"
                      :key="image.id"
                      :src="image.image"
                      width="128px"
                      height="128px"
                    />
                  </template>
                </b-form-group>
                <div class="row">
                  <b-form-group
                    class="col-6"
                    label="Meta Title"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="form.translation.indonesia.meta_title"
                      placeholder="Enter Meta Title"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="col-6"
                    id="input-group-7"
                    label="Meta Keywords"
                    label-for="input-4"
                  >
                    <b-form-input
                      id="input-4"
                      v-model="form.translation.indonesia.meta_keywords"
                      placeholder="Enter Meta Keywords"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group
                  id="input-group-3"
                  label="Meta Description"
                  label-for="input-3"
                  class="position-relative"
                >
                  <b-form-textarea
                    id="input-3"
                    v-model="form.translation.indonesia.meta_desc"
                    placeholder="Enter Meta Description"
                    rows="3"
                    :maxlength="250"
                  ></b-form-textarea>
                  <div class="text-right">
                    <p
                      v-if="form.translation.indonesia.meta_desc"
                      class="badge position-absolute"
                      style="top: 4px; right: 13px"
                      :class="{
                        'badge-success':
                          form.translation.indonesia.meta_desc.length !== 250,
                        'badge-danger':
                          form.translation.indonesia.meta_desc.length === 250,
                      }"
                    >
                      You typed
                      {{ form.translation.indonesia.meta_desc.length }} out of
                      250 chars.
                    </p>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import Layout from "../../layouts/main";
import MixinRequest from "../../../mixins/request";
import caseMixin from "../../../mixins/ModuleJs/case";
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";
import { required, requiredIf } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      // editor: "classic",
      // editorConfig: {
      //   stylesSet: [
      //     {
      //       name: "Left Alignment",
      //       element: "p",
      //       attributes: { class: "text-left" },
      //     },
      //     {
      //       name: "Center Alignment",
      //       element: "p",
      //       attributes: { class: "text-center" },
      //     },
      //     {
      //       name: "Right Alignment",
      //       element: "p",
      //       attributes: { class: "text-right" },
      //     },
      //     {
      //       name: "Justify Alignment",
      //       element: "p",
      //       attributes: { class: "text-justify" },
      //     },
      //   ],
      //   extraPlugins: "justify",
      // },
      optionsValue: ["A", "B", "C", "D"],
      settings: {
        autoplay: true,
        centerMode: true,
        centerPadding: "20px",
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
      },
      finalModel: {},
      submitted: false,
      store: false,
      interval: 0,
      slide: 0,
      sliding: null,
      title1: "Add Case",
      title2: "Edit Case",
      items: [
        {
          text: "Back",
          href: "/case",
        },
        {
          text: "Data",
        },
      ],
      image: [],
      editor: ClassicEditor,
    };
  },
  validations: {
    form: {
      title: { required },
      question_type: { required },
      community_selected: { required },
      description: { required },
      url_link: { required },
      partner_division_id: {
        required: requiredIf((form) => form.card_image_show != 4),
      },
      member_tagging: {
        required: requiredIf((form) => form.card_image_show !== 3),
      },
      addMore: {
        value: { required },
        $each: {
          question: { required },
          correct_option: { required },
        },
      },
    },
  },
  mixins: [MixinRequest, caseMixin],
  components: {
    ckeditor: CKEditor.component,
    Layout,
    Multiselect,
    FormWizard,
    TabContent,
  },
  methods: {
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.png");
      document.body.appendChild(link);
      link.click();
    },
    disabledDates() {
      return new Date().toISOString().slice(0, 16);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    validateFormOne() {
      this.submitted = true;
      let status = true;
      let correct_option = 0;
      if (this.form.question_type == "mcq") {
        for (let index = 0; index < this.form.addMore.length; index++) {
          for (let i = 0; i < this.form.addMore[index].option.length; i++) {
            if (this.form.addMore[index].option[i].value == "") {
              correct_option++;
            }
          }
          if (this.$v.form.addMore.$each[index].question.$invalid === true) {
            status = false;
          }
          if (
            this.$v.form.addMore.$each[index].correct_option.$invalid === true
          ) {
            status = false;
          }
        }
      }
      if (
        correct_option > 0 ||
        this.$v.form.title.$invalid ||
        this.$v.form.question_type.$invalid ||
        this.$v.form.description.$invalid ||
        this.$v.form.url_link.$invalid
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      return status;
    },
    validateFormTwo() {
      this.store = true;
      if (this.form.card_image_show == 0 || this.form.card_image_show == "") {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Select Posted By is required",
        });
        return false;
      }
      if (
        this.$v.form.community_selected.$invalid ||
        this.$v.form.partner_division_id.$invalid ||
        this.$v.form.member_tagging.$invalid
      ) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields",
        });
        return false;
      }
      if (this.form.view_multiplication_factor < 0) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "View Multiplication Factor should not be less than 0",
        });
        return false;
      }
      return true;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
